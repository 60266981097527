import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoginForm, LoginFormValue } from '@stur/components/common/login-form';
import { PageContent } from '@stur/components/layout/page-content';
import { PageTitle } from '@stur/components/layout/page-title';
import { useEffectOnce } from '@stur/hooks/use-effect-once';
import { ActionStatusSelectors } from '@stur/store/action-status/action-status-selectors';
import { AuthActions } from '@stur/store/auth/auth-reducer';
import { AuthSelectors } from '@stur/store/auth/auth-selectors';
import { RoutingUtils } from '@stur/utils/routing-utils';

export const LinkAccountPage: FC = () => {
  const dispatch = useDispatch();
  const linkAccount = useSelector(AuthSelectors.getLinkAccount);
  const isLoading = useSelector(
    ActionStatusSelectors.isActionPending([
      AuthActions.createAccountWithEmail,
      AuthActions.logIn,
      AuthActions.completeLogin,
    ])
  );

  // prevent access to this page if you're not in the link account flow
  // run on page load only
  useEffectOnce(() => {
    if (!linkAccount) {
      navigate(RoutingUtils.routes.logIn());
    }
  });

  const handleSubmit = (values: LoginFormValue): void => {
    const { email, password } = values;
    dispatch(AuthActions.createAccountWithEmail({ email, password }));
  };

  const handleFacebookAuth = (): void => {
    dispatch(AuthActions.logIn({ provider: 'facebook' }));
  };

  const handleGoogleAuth = (): void => {
    dispatch(AuthActions.logIn({ provider: 'google' }));
  };

  return (
    <>
      <PageTitle header="Link Account" />
      <PageContent>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell medium-6 large-4">
              <h2>
                There has already been an account created with this email address, lets link them
              </h2>
              <LoginForm
                isCreate={false}
                isLoading={isLoading}
                providers={linkAccount?.availableProviders}
                onSubmit={handleSubmit}
                onFacebookAuth={handleFacebookAuth}
                onGoogleAuth={handleGoogleAuth}
              />
            </div>
            <div className="cell medium-6 large-8">
              <img src="https://picsum.photos/800/800" alt="About Stur" className="img-fluid" />
            </div>
          </div>
        </div>
      </PageContent>
    </>
  );
};
